<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table row-key="id" :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="title" label="模版名称"></el-table-column>
        <el-table-column fixed="right" width="300" align="center" label="操作">
          <template #header>
            <el-button @click="editBox=true" size="mini" type="primary">添加</el-button>
          </template>
          <template #default="s">
            <el-button @click="$router.push({name:'shopGoodsShippingItems',query:{id:s.row.id}})" size="mini" type="primary">编辑详情</el-button>
            <el-button @click="edit(s.row)" size="mini" type="info">修改名称</el-button>
            <el-button @click="del(s.row.id)" size="mini" type="danger">移除</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
    <el-dialog title="编辑模版" :visible.sync="editBox" @close="editBoxClose">
      <div>
        <el-form label-width="70px">
          <el-form-item label="模版名称">
            <el-input v-model="editForm.title"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editBox = false">取 消</el-button>
        <el-button type="primary" @click="editSubmit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "list",
  components:{},
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      size: 15,
      editBox:false,
      editForm:{
        title:"",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    edit(item){
      this.editForm = {...item};
      this.editBox = true;
    },
    editSubmit(){
      this.$u.api.shop.goods.shippingEdit(this.editForm).then(()=>{
        this.$message.success("操作成功");
        this.load();
        this.editBox = false;
      })
    },
    editBoxClose(){
      this.editForm = {title: ""};
      this.editBox = false;
    },
    del(id) {
      this.$u.api.shop.goods.shippingDel({id}).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },

    load() {
      this.$u.api.shop.goods.shippingSearch({page: this.page}).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>